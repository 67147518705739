// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileAppVerification-module__qBt6Fbw3Cuv7pM4PtMNV {\n  flex-grow: 1;\n}\n\n.MobileAppVerification-module__ZGWkkVMoC8WZz46o0clH {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./containers/MobileAppVerification/MobileAppVerification.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".input {\n  flex-grow: 1;\n}\n\n.telegram-code {\n  font-weight: bolder;\n  padding: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "MobileAppVerification-module__qBt6Fbw3Cuv7pM4PtMNV",
	"telegram-code": "MobileAppVerification-module__ZGWkkVMoC8WZz46o0clH"
};
export default ___CSS_LOADER_EXPORT___;
