// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultPageLayout-module__PHyVR8KAElmUG2tKeoxm {\n  margin-top: -24px;\n}\n\n.DefaultPageLayout-module__PHyVR8KAElmUG2tKeoxm .DefaultPageLayout-module__DjjktFu2dpRBf_YZQdrj {\n  display: flex;\n  gap: 10px;\n}\n\n.DefaultPageLayout-module__PHyVR8KAElmUG2tKeoxm .DefaultPageLayout-module__o0G8B7hc5yocykoya3d8 {\n  margin: 24px 0;\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.DefaultPageLayout-module__PHyVR8KAElmUG2tKeoxm .filter-table td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n\n.DefaultPageLayout-module__w52UZJyEvRekN9li27Js {\n  color: var(--primary-text-link);\n}\n", "",{"version":3,"sources":["webpack://./containers/DefaultPageLayout/DefaultPageLayout.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA,+BAA+B;;AAE/B;EACE,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".root {\n  margin-top: -24px;\n}\n\n.root .alerts_horizontal {\n  display: flex;\n  gap: 10px;\n}\n\n.root .alert {\n  margin: 24px 0;\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.root :global(.filter-table) td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n\n.instructions-link {\n  color: var(--primary-text-link);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DefaultPageLayout-module__PHyVR8KAElmUG2tKeoxm",
	"alerts_horizontal": "DefaultPageLayout-module__DjjktFu2dpRBf_YZQdrj",
	"alert": "DefaultPageLayout-module__o0G8B7hc5yocykoya3d8",
	"instructions-link": "DefaultPageLayout-module__w52UZJyEvRekN9li27Js"
};
export default ___CSS_LOADER_EXPORT___;
