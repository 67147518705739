// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LiveSettings-module__kRuEmJBdjTCypWzbGJWQ {\n  margin-top: 24px;\n}\n\n.LiveSettings-module__yQZsEqahftWb0Fi_hdpw {\n  vertical-align: top;\n}\n\n.LiveSettings-module__mLyNfkqxadHBaYAjb9Y_ {\n  display: flex;\n  justify-content: space-between;\n}\n\n.LiveSettings-module__Bx_TB9aNz7tNJTPrewC5 {\n  /* vertical-align: top; */\n}\n\n.LiveSettings-module__PTqZpFZw2apF6VKgR7OL {\n  color: green;\n}\n\n.LiveSettings-module__lUrFMZobogU8uqWyrX3n > a {\n  color: var(--primary-text-link);\n}\n\n.LiveSettings-module__lUrFMZobogU8uqWyrX3n {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n", "",{"version":3,"sources":["webpack://./pages/livesettings/LiveSettings.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.align-top {\n  vertical-align: top;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.row {\n  /* vertical-align: top; */\n}\n\n.check-icon {\n  color: green;\n}\n\n.description-style > a {\n  color: var(--primary-text-link);\n}\n\n.description-style {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LiveSettings-module__kRuEmJBdjTCypWzbGJWQ",
	"align-top": "LiveSettings-module__yQZsEqahftWb0Fi_hdpw",
	"header": "LiveSettings-module__mLyNfkqxadHBaYAjb9Y_",
	"row": "LiveSettings-module__Bx_TB9aNz7tNJTPrewC5",
	"check-icon": "LiveSettings-module__PTqZpFZw2apF6VKgR7OL",
	"description-style": "LiveSettings-module__lUrFMZobogU8uqWyrX3n"
};
export default ___CSS_LOADER_EXPORT___;
