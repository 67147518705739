// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PluginConfigPage-module__AX6OjQTh4mhw1A8C7meA {\n  width: 100%;\n}\n\n.PluginConfigPage-module__ejkJ7u6TUBqcSeZK4rdi {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./containers/PluginConfigPage/PluginConfigPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".command-line {\n  width: 100%;\n}\n\n.info-block {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"command-line": "PluginConfigPage-module__AX6OjQTh4mhw1A8C7meA",
	"info-block": "PluginConfigPage-module__ejkJ7u6TUBqcSeZK4rdi"
};
export default ___CSS_LOADER_EXPORT___;
