// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SlackSettings-module__W2BX5BPiBPCAA2OOnpPZ {\n  margin-bottom: 20px;\n}\n\n.SlackSettings-module__HXlr_TQnOClM99q4bTjV {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.SlackSettings-module__eMlNeo1XDHi0cbJItizq .SlackSettings-module__R_UKdbn_4NTmviPwDEMH {\n  width: 400px;\n}\n\n.SlackSettings-module__PDPDXAYP7VzLrB2xsRAH {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n", "",{"version":3,"sources":["webpack://./pages/chat-ops/parts/tabs/SlackSettings/SlackSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kDAAkD;AACpD","sourcesContent":[".title {\n  margin-bottom: 20px;\n}\n\n.stub {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.root .select {\n  width: 400px;\n}\n\n.slack-settings {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "SlackSettings-module__W2BX5BPiBPCAA2OOnpPZ",
	"stub": "SlackSettings-module__HXlr_TQnOClM99q4bTjV",
	"root": "SlackSettings-module__eMlNeo1XDHi0cbJItizq",
	"select": "SlackSettings-module__R_UKdbn_4NTmviPwDEMH",
	"slack-settings": "SlackSettings-module__PDPDXAYP7VzLrB2xsRAH"
};
export default ___CSS_LOADER_EXPORT___;
