// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table-module__hCYj7XRxbhy7mmzNqZsQ {\n  width: 100%;\n}\n\n.Table-module__hCYj7XRxbhy7mmzNqZsQ table {\n  width: 100%;\n}\n\n.Table-module__hCYj7XRxbhy7mmzNqZsQ tr {\n  min-height: 56px;\n}\n\n.Table-module__hCYj7XRxbhy7mmzNqZsQ tr:hover {\n  /* background: var(--secondary-background); */\n}\n\n.Table-module__hCYj7XRxbhy7mmzNqZsQ th:first-child {\n  padding-left: 20px;\n}\n\n.Table-module__hCYj7XRxbhy7mmzNqZsQ td {\n  min-height: 60px;\n  padding: 10px 0;\n}\n\n.Table-module__NEm7SycBa8NmA6UzOcgS {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.Table-module__Lw4GBtXfz5JkV03LOoUM {\n  padding: 10px;\n  color: var(--primary-text-color);\n  pointer-events: none;\n  transform: rotate(-90deg);\n  transform-origin: center;\n  transition: transform 0.2s;\n}\n\n.Table-module__swTFiM1fnq2884qsLYE1 {\n  transform: rotate(0deg);\n}\n", "",{"version":3,"sources":["webpack://./components/Table/Table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,oBAAoB;EACpB,yBAAyB;EACzB,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".root {\n  width: 100%;\n}\n\n.root table {\n  width: 100%;\n}\n\n.root tr {\n  min-height: 56px;\n}\n\n.root tr:hover {\n  /* background: var(--secondary-background); */\n}\n\n.root th:first-child {\n  padding-left: 20px;\n}\n\n.root td {\n  min-height: 60px;\n  padding: 10px 0;\n}\n\n.pagination {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.expand-icon {\n  padding: 10px;\n  color: var(--primary-text-color);\n  pointer-events: none;\n  transform: rotate(-90deg);\n  transform-origin: center;\n  transition: transform 0.2s;\n}\n\n.expand-icon__expanded {\n  transform: rotate(0deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Table-module__hCYj7XRxbhy7mmzNqZsQ",
	"pagination": "Table-module__NEm7SycBa8NmA6UzOcgS",
	"expand-icon": "Table-module__Lw4GBtXfz5JkV03LOoUM",
	"expand-icon__expanded": "Table-module__swTFiM1fnq2884qsLYE1"
};
export default ___CSS_LOADER_EXPORT___;
