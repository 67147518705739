// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsPage-module__QMhdZRyuM9hu7FUy9iFe {\n  margin-top: 24px;\n}\n\n.SettingsPage-module__fVzl7ERYEHfm2X8zSRkP {\n  margin-bottom: 20px;\n}\n\n.SettingsPage-module__bWWoF_Avm_3_RKwIUvVW {\n  width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./pages/settings/SettingsPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.title {\n  margin-bottom: 20px;\n}\n\n.settings {\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SettingsPage-module__QMhdZRyuM9hu7FUy9iFe",
	"title": "SettingsPage-module__fVzl7ERYEHfm2X8zSRkP",
	"settings": "SettingsPage-module__bWWoF_Avm_3_RKwIUvVW"
};
export default ___CSS_LOADER_EXPORT___;
