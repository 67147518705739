// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MigrationTool-module__aopgZ93k1tWBxcfjFZOI {\n  margin-top: 24px;\n  width: 1000px;\n}\n\n.MigrationTool-module__aopgZ93k1tWBxcfjFZOI ul,\n.MigrationTool-module__aopgZ93k1tWBxcfjFZOI ol {\n  padding-left: 20px;\n}\n", "",{"version":3,"sources":["webpack://./pages/migration-tool/MigrationTool.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;;EAEE,kBAAkB;AACpB","sourcesContent":[".root {\n  margin-top: 24px;\n  width: 1000px;\n}\n\n.root ul,\n.root ol {\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MigrationTool-module__aopgZ93k1tWBxcfjFZOI"
};
export default ___CSS_LOADER_EXPORT___;
