// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GrafanaTeamSelect-module__HJhtX9nm6SB2zS3I1k34 {\n  width: 200px;\n  right: 0;\n  position: absolute;\n  padding: 16px 0;\n  margin-right: 24px;\n}\n\n.GrafanaTeamSelect-module__D2xhQ9prweaeYGzindcP {\n  display: flex;\n}\n\n.GrafanaTeamSelect-module__EzQZVJZB9Bpqcp7rXaQ7 {\n  position: absolute;\n  right: 0;\n}\n\n.GrafanaTeamSelect-module__OMXBO7MuU7RaBKjiC_7A {\n  margin-left: 4px;\n}\n", "",{"version":3,"sources":["webpack://./containers/GrafanaTeamSelect/GrafanaTeamSelect.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,QAAQ;EACR,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".teamSelect {\n  width: 200px;\n  right: 0;\n  position: absolute;\n  padding: 16px 0;\n  margin-right: 24px;\n}\n\n.teamSelectLabel {\n  display: flex;\n}\n\n.teamSelectLink {\n  position: absolute;\n  right: 0;\n}\n\n.teamSelectInfo {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamSelect": "GrafanaTeamSelect-module__HJhtX9nm6SB2zS3I1k34",
	"teamSelectLabel": "GrafanaTeamSelect-module__D2xhQ9prweaeYGzindcP",
	"teamSelectLink": "GrafanaTeamSelect-module__EzQZVJZB9Bpqcp7rXaQ7",
	"teamSelectInfo": "GrafanaTeamSelect-module__OMXBO7MuU7RaBKjiC_7A"
};
export default ___CSS_LOADER_EXPORT___;
