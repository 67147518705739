// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SlackTab-module__LyvDH3_885BJi9rX7BTC {\n  display: flex;\n  justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/SlackTab/SlackTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "SlackTab-module__LyvDH3_885BJi9rX7BTC"
};
export default ___CSS_LOADER_EXPORT___;
