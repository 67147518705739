// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TelegramInfo-module__xFKifzdlLVKOrnwF8YMF {\n  color: var(--primary-text-link);\n}\n\n.TelegramInfo-module__paEPKXDXoSoqPpaDsWft {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/TelegramInfo/TelegramInfo.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".verification-code {\n  color: var(--primary-text-link);\n}\n\n.verification-code-text {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verification-code": "TelegramInfo-module__xFKifzdlLVKOrnwF8YMF",
	"verification-code-text": "TelegramInfo-module__paEPKXDXoSoqPpaDsWft"
};
export default ___CSS_LOADER_EXPORT___;
