// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Incidents-module__rkjO02EViOuAXISFWbGS {\n  margin-top: 24px;\n}\n\n.Incidents-module__NVx5uhXF6DzsJRbhF1g4 {\n  width: 400px;\n}\n\n.Incidents-module__lasVaBt93PpS8IahJf1O {\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.Incidents-module__zooE0DVh7PJT9CJqNP_8 {\n  margin-bottom: 20px;\n}\n\n.Incidents-module__pZH_5AJok0KaaZwXJYYB {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.Incidents-module__YfItKZvUum2n6z_YO0BA {\n  margin: 10px 0 10px 0;\n}\n\n.Incidents-module__TBXctKLCrUZKp8X24gIr {\n  color: var(--secondary-text-color);\n}\n\n.Incidents-module___rV4s9MKQ54dmHiQTy1G {\n  font-size: 12px;\n  height: 24px;\n  margin-right: 0;\n}\n\n.Incidents-module__ze61YIJPFPwc4sPdwDZB {\n  width: 100%;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./pages/incidents/Incidents.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.select {\n  width: 400px;\n}\n\n.action-buttons {\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.filters {\n  margin-bottom: 20px;\n}\n\n.above-incidents-table {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.bulk-actions {\n  margin: 10px 0 10px 0;\n}\n\n.other-users {\n  color: var(--secondary-text-color);\n}\n\n.silence-button-inline {\n  font-size: 12px;\n  height: 24px;\n  margin-right: 0;\n}\n\n.pagination {\n  width: 100%;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Incidents-module__rkjO02EViOuAXISFWbGS",
	"select": "Incidents-module__NVx5uhXF6DzsJRbhF1g4",
	"action-buttons": "Incidents-module__lasVaBt93PpS8IahJf1O",
	"filters": "Incidents-module__zooE0DVh7PJT9CJqNP_8",
	"above-incidents-table": "Incidents-module__pZH_5AJok0KaaZwXJYYB",
	"bulk-actions": "Incidents-module__YfItKZvUum2n6z_YO0BA",
	"other-users": "Incidents-module__TBXctKLCrUZKp8X24gIr",
	"silence-button-inline": "Incidents-module___rV4s9MKQ54dmHiQTy1G",
	"pagination": "Incidents-module__ze61YIJPFPwc4sPdwDZB"
};
export default ___CSS_LOADER_EXPORT___;
