// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavBarSubtitle-module__w6iqD9vsyZUa_TPricaE {\n  display: flex;\n  align-items: center;\n}\n\n.NavBarSubtitle-module__MEnrHkTN7Vfr4uVwjwaO {\n  margin-right: 4px;\n}\n\n.NavBarSubtitle-module__zVqnwH41vaeRXoJeCNrZ {\n  padding: 4px;\n  margin: 0 0 0 8px;\n  border: 1px solid var(--gray-9);\n  width: initial;\n  font-size: 12px;\n}\n\n.NavBarSubtitle-module__gNCveri5CyWrb0gRgus4 {\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./components/NavBar/NavBarSubtitle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,+BAA+B;EAC/B,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n}\n\n.navbar-star-icon {\n  margin-right: 4px;\n}\n\n.navbar-heading {\n  padding: 4px;\n  margin: 0 0 0 8px;\n  border: 1px solid var(--gray-9);\n  width: initial;\n  font-size: 12px;\n}\n\n.navbar-link {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NavBarSubtitle-module__w6iqD9vsyZUa_TPricaE",
	"navbar-star-icon": "NavBarSubtitle-module__MEnrHkTN7Vfr4uVwjwaO",
	"navbar-heading": "NavBarSubtitle-module__zVqnwH41vaeRXoJeCNrZ",
	"navbar-link": "NavBarSubtitle-module__gNCveri5CyWrb0gRgus4"
};
export default ___CSS_LOADER_EXPORT___;
