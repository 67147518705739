// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Test-module__D_Gm7ouS9V30kNH1ODY8 {\n  margin-top: 24px;\n}\n\n.Test-module__zmXU0TkGnGHCILax1jXU {\n  width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./pages/test/Test.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.select {\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Test-module__D_Gm7ouS9V30kNH1ODY8",
	"select": "Test-module__zmXU0TkGnGHCILax1jXU"
};
export default ___CSS_LOADER_EXPORT___;
