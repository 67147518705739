// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Block-module__H_u6nsT4pWZ1giUUQ30v {\n  padding: 16px;\n  border-radius: 2px;\n}\n\n.theme-dark .Block-module__JzRFb_Cj_5i4IsI5F40t {\n  border: var(--border);\n}\n\n.theme-light .Block-module__JzRFb_Cj_5i4IsI5F40t {\n  border: var(--border);\n}\n\n.theme-dark .Block-module__ViFcjkVMoicbjhTSAGf0 {\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);\n}\n\n.Block-module__VSccfVyDU2S_xnjEnPWP {\n  background: var(--secondary-background);\n}\n", "",{"version":3,"sources":["webpack://./components/GBlock/Block.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".root {\n  padding: 16px;\n  border-radius: 2px;\n}\n\n:global(.theme-dark) .root_bordered {\n  border: var(--border);\n}\n\n:global(.theme-light) .root_bordered {\n  border: var(--border);\n}\n\n:global(.theme-dark) .root_shadowed {\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);\n}\n\n.root_with-background {\n  background: var(--secondary-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Block-module__H_u6nsT4pWZ1giUUQ30v",
	"root_bordered": "Block-module__JzRFb_Cj_5i4IsI5F40t",
	"root_shadowed": "Block-module__ViFcjkVMoicbjhTSAGf0",
	"root_with-background": "Block-module__VSccfVyDU2S_xnjEnPWP"
};
export default ___CSS_LOADER_EXPORT___;
