// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SchedulesFilters-module__LjXlt9zawM5bxe_4sG6j {\n  display: inline-flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./components/SchedulesFilters_NEW/SchedulesFilters.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":[".root {\n  display: inline-flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SchedulesFilters-module__LjXlt9zawM5bxe_4sG6j"
};
export default ___CSS_LOADER_EXPORT___;
