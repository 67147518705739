// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhooks-module__vgjESRVNOf_7cURUUwii {\n  margin-top: 24px;\n}\n\n.OutgoingWebhooks-module__fAcfODf2ZTs1IdhVw5sv {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./pages/outgoing_webhooks/OutgoingWebhooks.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OutgoingWebhooks-module__vgjESRVNOf_7cURUUwii",
	"header": "OutgoingWebhooks-module__fAcfODf2ZTs1IdhVw5sv"
};
export default ___CSS_LOADER_EXPORT___;
