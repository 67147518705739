// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TelegramSettings-module__hrBZC7I44JQgwsalhAd2 {\n  display: block;\n}\n\n.TelegramSettings-module__P2Sg_HuQpZ82A7ogBs02 {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./pages/chat-ops/parts/tabs/TelegramSettings/TelegramSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".root {\n  display: block;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TelegramSettings-module__hrBZC7I44JQgwsalhAd2",
	"header": "TelegramSettings-module__P2Sg_HuQpZ82A7ogBs02"
};
export default ___CSS_LOADER_EXPORT___;
