// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TelegramIntegrationButton-module__ov44mKjqGol5o4avJFfk {\n  color: var(--primary-text-link);\n}\n\n.TelegramIntegrationButton-module__lsSzmt2PCTzK8MIRd_Ap {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.TelegramIntegrationButton-module__wnJcVsHKozTveH9G7AiX {\n  text-decoration: underline;\n}\n\n.TelegramIntegrationButton-module__nztqWoeJWlUJM90Ddzrz {\n  color: var(--primary-text-link);\n}\n\n.TelegramIntegrationButton-module__dpkX4MjnrTHa3Ynkt6Vp {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./containers/TelegramIntegrationButton/TelegramIntegrationButton.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".telegram-bot {\n  color: var(--primary-text-link);\n}\n\n.telegram-instruction-container {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.verification-code {\n  text-decoration: underline;\n}\n\n.copy-icon {\n  color: var(--primary-text-link);\n}\n\n.telegram-instruction-cancel {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telegram-bot": "TelegramIntegrationButton-module__ov44mKjqGol5o4avJFfk",
	"telegram-instruction-container": "TelegramIntegrationButton-module__lsSzmt2PCTzK8MIRd_Ap",
	"verification-code": "TelegramIntegrationButton-module__wnJcVsHKozTveH9G7AiX",
	"copy-icon": "TelegramIntegrationButton-module__nztqWoeJWlUJM90Ddzrz",
	"telegram-instruction-cancel": "TelegramIntegrationButton-module__dpkX4MjnrTHa3Ynkt6Vp"
};
export default ___CSS_LOADER_EXPORT___;
