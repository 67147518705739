// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedules-module__Tl0SY92WDgg1j7SQLmEi {\n  margin-top: 24px;\n}\n\n.Schedules-module__y_mdfTxJV8xlVUfJIo4u {\n  color: var(--warning-text-color);\n}\n\n.Schedules-module__AsNIrNxuvPyDWKaH4ROS {\n  position: relative;\n  margin: 20px 0;\n}\n\n.Schedules-module__ARVtxzWJ821PM3tAq4lC {\n  padding-left: 20px;\n}\n\n/*\n.root .expanded-row {\n  background: var(--secondary-background);\n}\n*/\n", "",{"version":3,"sources":["webpack://./pages/schedules_NEW/Schedules.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;;CAIC","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.quality__type_success {\n  color: var(--warning-text-color);\n}\n\n.schedule {\n  position: relative;\n  margin: 20px 0;\n}\n\n.loader {\n  padding-left: 20px;\n}\n\n/*\n.root .expanded-row {\n  background: var(--secondary-background);\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Schedules-module__Tl0SY92WDgg1j7SQLmEi",
	"quality__type_success": "Schedules-module__y_mdfTxJV8xlVUfJIo4u",
	"schedule": "Schedules-module__AsNIrNxuvPyDWKaH4ROS",
	"loader": "Schedules-module__ARVtxzWJ821PM3tAq4lC"
};
export default ___CSS_LOADER_EXPORT___;
